import React from 'react'
import { ConnectorSourceDestination, ConnectorBiDestination } from '../components'
 
const ConnectorDestinationTemplate = ({ pageContext, location }) => {
  return (
    <ConnectorSourceDestination 
      location={location} 
      pageContext={pageContext}
    />
  )
}

export default ConnectorDestinationTemplate
